<template>
  <div>
    <h2
      class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12"
    >
      Perbaharui Kata Sandi
    </h2>
    <b-form id="form" @submit="onSubmit">
      <b-form-group id="input-group-sktAktif" label-for="inputSktAktif">
        <template v-slot:label>
          Kata Sandi
        </template>
        <b-form-input
          id="inputSktAktif"
          name="inputSktAktif"
          v-model="$v.form.pass.$model"
          placeholder="Silahkan Masukan Kata sandi"
          aria-describedby="inputSktAktif"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-noRegistrasi" label-for="inputNoRegistrasi">
        <template v-slot:label>
          Konfirmasi Kata Sandi
        </template>
        <b-form-input
          id="inputNoRegistrasi"
          name="inputNoRegistrasi"
          v-model="$v.form.confirm_pass.$model"
          placeholder="Silahkan Konfirmasi Kata sandi"
          :state="validateState('confirm_pass')"
          aria-describedby="inputNoRegistrasi"
        ></b-form-input>

        <b-form-invalid-feedback id="inputNoRegistrasi"
          >Kata sandi tidak sesuai</b-form-invalid-feedback
        >
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" class="button-aktivasi"
          >Perbaharui Kata Sandi</b-button
        >
        <!--        <b-button class="ml-2" @click="resetForm()">Reset</b-button>-->
      </div>
    </b-form>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "ActivationFloat",
  props: ["length"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        pass: "",
        confirm_pass: ""
      }
    };
  },
  validations: {
    form: {
      pass: {
        required
      },
      confirm_pass: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pass: null,
        confirm_pass: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.$v.form.$touch();
      if (!this.$v.$invalid) {
        Swal.fire({
          icon: "success",
          title: "Buat kata sandi berhasil, silahkan login",
          // text: "Silahkan cek email anda untuk melihat sandi anda",
          confirmButtonColor: "#063A69",
          confirmButtonText: "Login"
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      }
      if (this.$v.form.$anyError) {
        return false;
      }
      // alert("Form submitted!");
    }
    /*onSubmit(evt) {
      evt.preventDefault();

      let data = {
        no_skt: this.form.sktAktif,
        no_skt_lama: this.form.sktLama,
        no_ktp: this.form.ktp,
        nama_lengkap: this.form.nama,
        no_registrasi: this.form.registrasi,
        email: this.form.email,
        no_hp: this.form.noHp,
        url_callback: "https://siormas.spbe.sangkuriang.co.id/aktivasi",
        url_login: "https://siormas.spbe.sangkuriang.co.id/login"
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      };


      /!*Swal.fire({
        title: "Aktivasi",
        text: "Apakah Anda yakin aktivasi No. SKT ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#063A69",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              fetch(
                "https://api.siormas.spbe.sangkuriang.co.id/aktivasi",
                requestOptions
              )
                .then(async response => {
                  const data = await response.json();

                  // check for error response
                  if (!response.ok) {
                    await Swal.fire(response.error.message, "error");
                    reject();
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                  }

                  // this.postId = data.id;
                })
                .catch(error => {
                })
                .then(function(response) {
                  Swal.fire(response.message, "success");
                  resolve();
                });
            }
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });*!/

      fetch(
        "https://api.siormas.spbe.sangkuriang.co.id/aktivasi",
        requestOptions
      )
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          // this.postId = data.id;
        })
        .catch(error => {
          this.errorMessage = error;
        });
    }*/
  }
};
</script>

<style scoped>
.button-aktivasi {
  margin-top: 50px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}
</style>
